import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import SearchView from '../views/SearchView.vue'
import BullDetailView from '../views/BullDetailView.vue'
import VueI18n from "@/i18n/entry";
import { Locales } from '@/i18n/config/locales';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:locale?/query/:searchTerm?',
    name: 'search',
    component: SearchView,
    props: route => ({
        locale: route.params.locale || Locales.nlNl,
        searchTerm: decodeURIComponent(route.params.searchTerm as string),
    })
  },
  {
    path: '/:locale/detail/:interbullNumber/:base/:calculationType/tab/:tabParam?/query/:searchTerm?',
    name: 'bull-detail',
    component: BullDetailView,
    props: route => ({
        locale: route.params.locale || Locales.nlNl,
        interbullNumber: route.params.interbullNumber,
        base: route.params.base,
        calculationType: route.params.calculationType,
        tabParam: route.params.tabParam,
        searchTerm: decodeURIComponent(route.params.searchTerm as string) || '',
    })
  },
  {
    path: '/:pathMatch(.*)*', // catch all
    redirect: { name: 'search' }
  }
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

router.afterEach((to) => {
  if (to.params.locale) {
    VueI18n.global.locale = to.params.locale as Locales
  } else {
    VueI18n.global.locale = Locales.nlNl
  }
})

export default router
