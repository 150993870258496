<style lang="scss" scoped>
@import '../../scss/detail.scss';
</style>

<template>
    <div class="summary__container__listOne">
        <div class="list-item">
            <div class="summary__container__listOne__columnOne"> {{ $t('details.summary.first.artificialInseminationNumber') }}</div>
            <div class="summary__container__listOne__columnTwo">{{ formatEmptyToDash(bullDetail.artificialInseminationNumber) }}</div>
        </div>
        <div class="list-item">
            <div class="summary__container__listOne__columnOne"> {{ $t('details.summary.first.parentBulls') }}</div>
            <div class="summary__container__listOne__columnTwo">{{ bullDetail.parentBulls ? bullDetail.parentBulls : $t('home.unknown')}}</div>
        </div>
        <div class="list-item">
            <div class="summary__container__listOne__columnOne"> {{ $t('details.summary.first.interbullNumber') }}</div>
            <div class="summary__container__listOne__columnTwo">{{ formatEmptyToDash(bullDetail.interbullNumber) }}</div>
        </div>
        <div class="list-item">
            <div class="summary__container__listOne__columnOne"> {{ $t('details.summary.first.lifeNumber') }}</div>
            <div class="summary__container__listOne__columnTwo">{{ formatEmptyToDash(bullDetail.lifeNumber) }}</div>
        </div>
        <div class="list-item">
            <div class="summary__container__listOne__columnOne"> {{ $t('details.summary.first.raceInfo') }}</div>
            <div class="summary__container__listOne__columnTwo">{{ formatEmptyToDash(bullDetail.breed) }}</div>
        </div>
        <div class="list-item">
            <div class="summary__container__listOne__columnOne"> {{ $t('details.summary.first.dateOfBirth') }}</div>
            <div class="summary__container__listOne__columnTwo">{{ formatDate(bullDetail.dateOfBirth, true) }}</div>
        </div>
        <div class="list-item">
            <div class="summary__container__listOne__columnOne"> {{ $t('details.summary.first.hairColor') }}</div>
            <div class="summary__container__listOne__columnTwo">{{ $t(bullDetail.hairColor) }}</div>
        </div>
        <!-- <div class="list-item">
            <div class="summary__container__listOne__columnOne"> {{ $t('details.summary.first.tripleACode') }}</div>
            <div class="summary__container__listOne__columnTwo">{{ formatEmptyToDash(bullDetail.tripleACode?.toString()) }}</div>
        </div> -->
        <div class="list-item">
            <div class="summary__container__listOne__columnOne"> {{ $t('details.summary.first.inheritedFactors') }}</div>
            <div class="summary__container__listOne__columnTwo">{{ formatValueOrDash(formatInheritedFactors(bullDetail.inheritedFactors)) }}</div>
        </div>

        <div class="list-item">
            <div class="summary__container__listOne__columnOne"> {{ $t('details.summary.first.inbreedingCoefficient') }}</div>
            <div class="summary__container__listOne__columnTwo">{{ formatValueOrDash(bullDetail.inbreedingCoefficient, true) }}</div>
        </div>
        <div class="list-item">
            <div class="summary__container__listOne__columnOne"> {{ $t('details.summary.first.kinshipRate') }}</div>
            <div class="summary__container__listOne__columnTwo">{{ formatValueOrDash(bullDetail.kinshipRate) }}</div>
        </div>
        <div class="list-item">
            <div class="summary__container__listOne__columnOne"> {{ $t('details.summary.first.sourceBreedingValues') }}</div>
            <div class="summary__container__listOne__columnTwo">{{ $t('details.general.CRV') }} {{bullDetail.dateOfOfficialEvaluation}}</div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { formatDateMixin } from '../../mixins/format-date';
import { formatValueOrDash } from '../../scripts/format-value-or-dash';
import { mapGetters } from 'vuex';
import {formatInheritedFactors} from "../../scripts/format-inherited-factors";
import {defaultLocale} from "../../i18n/config";

export default defineComponent({
    name: 'ListBlockView',
    mixins:[formatDateMixin],
    computed: {
      defaultLocale() {
        return defaultLocale
      },
        ...mapGetters({
            bullDetail: 'getBullDetail',
        }),
    },
    methods: {
      formatInheritedFactors,
      formatValueOrDash,
        formatEmptyToDash(value: number | string, round?: boolean) {
            if (typeof value === 'string') {
                const nullValue = 'null';
                const newValue = (value as string).replace(nullValue, this.$t('details.unknown'))
                return formatValueOrDash(newValue);
            } else {
                return formatValueOrDash(value, round);
            }
        }
    },
});
</script>