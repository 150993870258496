<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'ProductionTable',
    props: {
        bodyRows: {
            type: Array,
            required: true
        },
        headerRows: {
            type: Array,
            required: true
        },
        footerRows: {
            type: Array,
            required: false,
            note: 'FooterRows equals Overall row'
        }
    },
});
</script>

<template>
    <v-table density="compact">
        <thead>
            <tr>
                <th v-for="(item, index) in headerRows[0].cells" :key="item.id" class="text-end">
                    <template v-if="item.slotName === 'custom-styled-column1'">
                        <!-- TODO: what is this custom-styled-column1 nonsense -->
                        <div>
                            <div class="text-end">
                                {{ item.value }}
                            </div>
                            <div class="header-sub-text">
                                <p v-if="footerRows[0].cells[index].sourceCompany">
                                    ({{ $t('details.source.' + footerRows[0].cells[index].sourceCompany) }})
                                </p>
                                <!-- What if the bodyCells contain different sourceCompanies? what should the header show -->
                                <!-- <p v-if="bodyRows[0].cells[index].sourceCompany">
                                    ({{ $t('details.source.' + bodyRows[0].cells[index].sourceCompany) }})
                                </p> -->
                                <p v-else class="space-reserver"></p>
                            </div>
                        </div>
                    </template>
                    <div v-else>
                        <p>{{ item.value }}</p>
                        <p class="space-reserver"></p>
                    </div>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="row in bodyRows" :key="row.id">
                <td  
                    v-for="cell in row.cells" 
                    :key="cell.id"
                    class="tabular-nums text-end"
                >
                    {{ cell.value }}
                </td>
            </tr>
        </tbody>
        <tfoot>
            <tr v-for="row in footerRows" :key="row.id">
                <td 
                    v-for="cell in row.cells"
                    :key="cell.id"
                    class="tabular-nums text-end"
                >
                    {{ cell.value }}
                </td>
            </tr>
        </tfoot>
    </v-table>
</template>

<style lang="scss" scoped>
@import '../scss/breeding.scss';

.tabular-nums {
    font-variant-numeric: tabular-nums;
}

/* Ensure all cells align text to the end */
.text-end {
    text-align: end;
}

.space-reserver {
    height: 21px;
}
</style>
