
// If the number is nada, return a dash; when positive, add a plus sign; otherwise, return the number as is.

  export function formatSignedValue(value: string | number | null | undefined) {
    if (value === undefined || value === null || value === '') {
      return '-';
    }
    if (typeof value === 'number') {
      return value > 0 ? `+${value}` : `${value}`;
    }
    return value;
}