<template>
    <div class="footer">
        <p v-if="showDisclaimer" class="disclaimer text-caption">
            {{ $t("footer.disclaimer")}}
        </p>
        <crvLogo v-else />
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import crvLogo from '../assets/logo/crvLogo.vue';

export default defineComponent({
    name: "SireFooter",
    components: { crvLogo },
    props: {
        showDisclaimer: {
            type: Boolean,
            default: false
        },
    },
});
</script>

<style lang="scss" scoped>

.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 121px;
    margin-top: 0;
    padding: 0 48px;

    @include mobile {
        justify-content: center;
        height: auto;
        margin-top: $vertical-spacing-single;
    }
}

.disclaimer {
    text-align: center;
    vertical-align: middle;
    display: table-cell;
}

.crv-logo {
    width: 139px;
    height: 52px;
    display: inline-block;
    fill: #7f746f;

    @include mobile {
        width: 120px;
    }

    display: flex;
}

svg:not(:root) {
    overflow: hidden;
}

</style>