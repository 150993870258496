<template>
  <div style="height:70vh">

    <crv-fallback-content
        image-name="tools"
        :title="$t('maintenance-page.title')"
        :text="$t('maintenance-page.maintenance')"
    />
    <p class="text-center sub-text">
        {{$t("maintenance-page.sorry")}}
    </p>
  </div>
  <SireFooter />
</template>

<script lang="ts">
import {defineComponent} from "vue"
import SireFooter from '../components/SireFooter.vue'
import { CrvFallbackContent } from '../../node_modules/@crv/vue-3-components'

export default defineComponent({
  name: "MaintenanceView",

  components: {
    SireFooter,
    CrvFallbackContent
    }
})
</script>


<style lang="scss" scoped>
.sub-text {
  font-size: 14px;
}
</style>