import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = {
  key: 3,
  class: "cell-container"
}
const _hoisted_6 = { class: "hobv-bar" }
const _hoisted_7 = { key: 4 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HorizontalBreedingValueBar = _resolveComponent("HorizontalBreedingValueBar")!
  const _component_v_table = _resolveComponent("v-table")!

  return (_openBlock(), _createBlock(_component_v_table, { density: "compact" }, {
    default: _withCtx(() => [
      _createElementVNode("thead", {
        class: _normalizeClass(_ctx.headerRows[0].cells.length > 3 ? 'detailed-header' : 'general-header')
      }, [
        _createElementVNode("tr", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headerRows[0].cells, (item) => {
            return (_openBlock(), _createElementBlock("th", {
              key: item.id,
              class: _normalizeClass([item.id, "text-end fixed-width"])
            }, [
              (item.slotName === 'custom-styled-column1')
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(["custom-bar-header", _ctx.hideHeader ? 'hidden' : null])
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getBreedingDeviationValues(item.id), (value) => {
                      return (_openBlock(), _createElementBlock("div", { key: value }, _toDisplayString(value), 1))
                    }), 128))
                  ], 2))
                : (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(item.value), 1))
            ], 2))
          }), 128))
        ])
      ], 2),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bodyRows, (row) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: row.id
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.cells, (cell) => {
              return (_openBlock(), _createElementBlock("td", {
                key: cell.id,
                class: _normalizeClass([_ctx.getCellClass(cell), "fixed-width table-body"])
              }, [
                (cell.slotName === 'bar-sub-text-left')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.addDutchTranslatedSubtextLeft(row.id)), 1))
                  : _createCommentVNode("", true),
                (cell.slotName === 'custom-styled-column-3')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.formatBreedingValues(cell.value)), 1))
                  : _createCommentVNode("", true),
                (!(cell.id === 'value' || cell.id === 'measurement'))
                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.showIndentationForMilkingRobot(cell.value)), 1))
                  : _createCommentVNode("", true),
                (cell.slotName === 'custom-styled-column')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createElementVNode("div", _hoisted_6, [
                        _createVNode(_component_HorizontalBreedingValueBar, {
                          "with-sub-text": _ctx.withSubText,
                          deviationValues: _ctx.getBreedingDeviationValues(row.id),
                          value: cell.value,
                          "xbar-colors": {default: '#4881b5'},
                          "use-multiple-colors": false
                        }, null, 8, ["with-sub-text", "deviationValues", "value"])
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (cell.slotName === 'bar-sub-text-right')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.addDutchTranslatedDifferentSubtextRight(row.id)), 1))
                  : _createCommentVNode("", true)
              ], 2))
            }), 128))
          ]))
        }), 128))
      ])
    ]),
    _: 1
  }))
}