// TODO: Hard-copy from crv-sireMatch, normally imported from within vite.config.ts - since this project is not a vite project yet, accept hard-copy untill converted?

// Vuetify styles (like .text-h1) were not imported using import 'vuetify/styles' (from the docs)
import 'vuetify/lib/styles/main.css' 

import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { CrvTheme } from '@crv/vue-3-components'
// TODO: Get theming from vue-3-components
// Also remove the base-colors-copy.modules.scss and crvThemeCopy.js when done.


// import CrvTheme from "./crvThemeCopy"
import { mdi, aliases } from 'vuetify/iconsets/mdi-svg'
// TODO apparently the aliasses are not working yet...
import { mdiMagnify, mdiChevronLeft, mdiChevronRight, mdiFilePdfBox, mdiHelpCircle, mdiCog, mdiPrinter, mdiGenderFemale, mdiGenderMale,
    mdiFormatListBulletedType, mdiMinus, mdiArrowUpThin, mdiArrowDownThin, mdiAlert, mdiOpenInNew } from '@mdi/js'
import * as labsComponents from 'vuetify/labs/components'


// * we are not importing vuetify/iconsets/mdi because this increases the build size with 3MB (all mdi icons)
// * instead we only import vuetify internal icons from vuetify/iconsets/mdi-svg
// * for a list of these icons see https://github.com/vuetifyjs/vuetify/blob/master/packages/vuetify/src/iconsets/mdi-svg.ts
// * 
// * for mdi icons used in Sirematch but not provided by vuetify the procedure is as follows:
// *
// * 1. import the icon from @mdi/js (is treeshakeable):
// *    import { mdiCheck } from '@mdi/js'
// *
// * 2. define an alias for the icon:
// *    aliases: {
// *        ...aliases,
// *        check: mdiCheck,
// *    },
// *
// * Example usage of vuetify or custom icons: <v-icon :icon="enums.Icon.CHECK" /> or <v-icon icon="$check" />


export const vuetify = createVuetify({
    components: {
        ...components,
        ...labsComponents,
    },   
    directives,
    theme: {
        defaultTheme: 'CrvTheme',
        themes: {
            CrvTheme,
        }
    },
    icons: {
        defaultSet: 'mdi',
        aliases: {
            ...aliases,
            alert: mdiAlert,
            magnify: mdiMagnify,
            chevronLeft: mdiChevronLeft,
            chevronRight: mdiChevronRight,
            genderFemale: mdiGenderFemale,
            genderMale: mdiGenderMale,
            pdf: mdiFilePdfBox,
            sortAsc: mdiArrowUpThin, // Overwrite vuetify default
            sortDesc: mdiArrowDownThin, // Overwrite vuetify default
            help: mdiHelpCircle,
            cog: mdiCog,
            printer: mdiPrinter,
            formatListBulletedType: mdiFormatListBulletedType,
            minus: mdiMinus,
            newTab: mdiOpenInNew,
        },
        sets: {
            mdi,
        }
    },
})
