import { formatNumber} from './format-number';


const breedingValuesMap = new Map<string, number>([
  ['DryMatterIntake', 2],
  ['FoodCostSavingMaintenance', 2],
  ['Ureum', 1],
])

export const formatBreedingValues = (value) => {
  if (value.value === null || value.value === undefined) {
    return '-'
  }

  const precision = breedingValuesMap.get(value.tagName) ?? 0;

  if (precision !== undefined) {
    return formatNumber(value.value.toFixed(precision))
  }
}