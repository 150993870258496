import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "summary__container__listOne" }
const _hoisted_2 = { class: "list-item" }
const _hoisted_3 = { class: "summary__container__listOne__columnOne" }
const _hoisted_4 = { class: "summary__container__listOne__columnTwo" }
const _hoisted_5 = { class: "list-item" }
const _hoisted_6 = { class: "summary__container__listOne__columnOne" }
const _hoisted_7 = { class: "summary__container__listOne__columnTwo" }
const _hoisted_8 = { class: "list-item" }
const _hoisted_9 = { class: "summary__container__listOne__columnOne" }
const _hoisted_10 = { class: "summary__container__listOne__columnTwo" }
const _hoisted_11 = { class: "list-item" }
const _hoisted_12 = { class: "summary__container__listOne__columnOne" }
const _hoisted_13 = { class: "summary__container__listOne__columnTwo" }
const _hoisted_14 = { class: "list-item" }
const _hoisted_15 = { class: "summary__container__listOne__columnOne" }
const _hoisted_16 = { class: "summary__container__listOne__columnTwo" }
const _hoisted_17 = { class: "list-item" }
const _hoisted_18 = { class: "summary__container__listOne__columnOne" }
const _hoisted_19 = { class: "summary__container__listOne__columnTwo" }
const _hoisted_20 = { class: "list-item" }
const _hoisted_21 = { class: "summary__container__listOne__columnOne" }
const _hoisted_22 = { class: "summary__container__listOne__columnTwo" }
const _hoisted_23 = { class: "list-item" }
const _hoisted_24 = { class: "summary__container__listOne__columnOne" }
const _hoisted_25 = { class: "summary__container__listOne__columnTwo" }
const _hoisted_26 = { class: "list-item" }
const _hoisted_27 = { class: "summary__container__listOne__columnOne" }
const _hoisted_28 = { class: "summary__container__listOne__columnTwo" }
const _hoisted_29 = { class: "list-item" }
const _hoisted_30 = { class: "summary__container__listOne__columnOne" }
const _hoisted_31 = { class: "summary__container__listOne__columnTwo" }
const _hoisted_32 = { class: "list-item" }
const _hoisted_33 = { class: "summary__container__listOne__columnOne" }
const _hoisted_34 = { class: "summary__container__listOne__columnTwo" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('details.summary.first.artificialInseminationNumber')), 1),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.formatEmptyToDash(_ctx.bullDetail.artificialInseminationNumber)), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('details.summary.first.parentBulls')), 1),
      _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.bullDetail.parentBulls ? _ctx.bullDetail.parentBulls : _ctx.$t('home.unknown')), 1)
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('details.summary.first.interbullNumber')), 1),
      _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.formatEmptyToDash(_ctx.bullDetail.interbullNumber)), 1)
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('details.summary.first.lifeNumber')), 1),
      _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.formatEmptyToDash(_ctx.bullDetail.lifeNumber)), 1)
    ]),
    _createElementVNode("div", _hoisted_14, [
      _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t('details.summary.first.raceInfo')), 1),
      _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.formatEmptyToDash(_ctx.bullDetail.breed)), 1)
    ]),
    _createElementVNode("div", _hoisted_17, [
      _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.$t('details.summary.first.dateOfBirth')), 1),
      _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.formatDate(_ctx.bullDetail.dateOfBirth, true)), 1)
    ]),
    _createElementVNode("div", _hoisted_20, [
      _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.$t('details.summary.first.hairColor')), 1),
      _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.$t(_ctx.bullDetail.hairColor)), 1)
    ]),
    _createElementVNode("div", _hoisted_23, [
      _createElementVNode("div", _hoisted_24, _toDisplayString(_ctx.$t('details.summary.first.inheritedFactors')), 1),
      _createElementVNode("div", _hoisted_25, _toDisplayString(_ctx.formatValueOrDash(_ctx.formatInheritedFactors(_ctx.bullDetail.inheritedFactors))), 1)
    ]),
    _createElementVNode("div", _hoisted_26, [
      _createElementVNode("div", _hoisted_27, _toDisplayString(_ctx.$t('details.summary.first.inbreedingCoefficient')), 1),
      _createElementVNode("div", _hoisted_28, _toDisplayString(_ctx.formatValueOrDash(_ctx.bullDetail.inbreedingCoefficient, true)), 1)
    ]),
    _createElementVNode("div", _hoisted_29, [
      _createElementVNode("div", _hoisted_30, _toDisplayString(_ctx.$t('details.summary.first.kinshipRate')), 1),
      _createElementVNode("div", _hoisted_31, _toDisplayString(_ctx.formatValueOrDash(_ctx.bullDetail.kinshipRate)), 1)
    ]),
    _createElementVNode("div", _hoisted_32, [
      _createElementVNode("div", _hoisted_33, _toDisplayString(_ctx.$t('details.summary.first.sourceBreedingValues')), 1),
      _createElementVNode("div", _hoisted_34, _toDisplayString(_ctx.$t('details.general.CRV')) + " " + _toDisplayString(_ctx.bullDetail.dateOfOfficialEvaluation), 1)
    ])
  ]))
}