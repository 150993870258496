import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "footer" }
const _hoisted_2 = {
  key: 0,
  class: "disclaimer text-caption"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_crvLogo = _resolveComponent("crvLogo")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showDisclaimer)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.$t("footer.disclaimer")), 1))
      : (_openBlock(), _createBlock(_component_crvLogo, { key: 1 }))
  ]))
}