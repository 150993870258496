
const isEmpty = (val: any): boolean => {
    return val === null || val === undefined || (typeof val === 'string' && val.trim() === '')
}

// TODO: candidate for Monorepo service function
export const sort = (a, b, sortBy) => {
    // vuetify.d.ts shim is necessary to avoid typescript error when using sortRaw in headers of v-data-table
    // V-data-table doesn't seem to have proper documentation on adding custom-sorting.
    // Below's function add Natural sorting, plus places the 'empty' or '-' below the rest of the values.
    
    const stringCollator = new Intl.Collator('nl', { sensitivity: 'accent', usage: 'sort' })

    const modifiers = { 
        asc: 1, 
        desc: -1
    }
    const property = sortBy[0].key
    const order = sortBy[0].order
    const modifier = modifiers[order]
    const sortA = a[property]
    const sortB = b[property]

    if (sortA === sortB) {
        return 0
    }

    if (sortA === '-' && sortB !== '-') {
        return modifier
    }
    if (sortB === '-' && sortA !== '-') {
        return -1 * modifier
    }

    if (isEmpty(sortA) && isEmpty(sortB)) return 0
    if (isEmpty(sortA)) return -1
    if (isEmpty(sortB)) return 1
    if (!isNaN(sortA) && !isNaN(sortB)) return Number(sortA) - Number(sortB)
    return stringCollator.compare(sortA, sortB)
}