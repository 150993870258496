<template>
    <v-table density="compact">
        <thead :class="headerRows[0].cells.length > 3 ? 'detailed-header' : 'general-header'">
            <tr>
                <th 
                    v-for="item in headerRows[0].cells" 
                    :key="item.id"
                    :class="item.id"
                    class="text-end fixed-width"
                >
                    <template v-if="item.slotName === 'custom-styled-column1'">
                        <div class="custom-bar-header" :class="hideHeader ? 'hidden' : null">
                            <div v-for="value in getBreedingDeviationValues(item.id)" :key="value">
                                {{ value }}
                            </div>
                        </div>
                    </template>
                    <span v-else>
                        {{ item.value }}
                    </span>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="row in bodyRows" :key="row.id">
                <td v-for="cell in  row.cells"
                    :key="cell.id"
                    :class="getCellClass(cell)" 
                    class="fixed-width table-body"
                >
                <!-- TODO: Above classed we'd like to be based on cell.alignment but somehow it's not present? Using cell.slotName instead-->
                    <template v-if="cell.slotName === 'bar-sub-text-left'">
                        <div>
                            {{ addDutchTranslatedSubtextLeft(row.id) }}
                        </div>
                    </template>
                    <template v-if="cell.slotName === 'custom-styled-column-3'">
                        <div>
                            {{  formatBreedingValues(cell.value) }}
                        </div>
                    </template>
                    <span v-if="!(cell.id === 'value' || cell.id === 'measurement')">
                        {{ showIndentationForMilkingRobot(cell.value) }}
                    </span>
                    <template v-if="cell.slotName === 'custom-styled-column'">
                        <div class="cell-container">
                            <div class="hobv-bar">
                                <HorizontalBreedingValueBar 
                                    :with-sub-text="withSubText"
                                    :deviationValues="getBreedingDeviationValues(row.id)"
                                    :value="cell.value"
                                    :xbar-colors="{default: '#4881b5'}"
                                    :use-multiple-colors="false"
                                />
                            </div>
                        </div>

                    </template>
                    <template v-if="cell.slotName === 'bar-sub-text-right'">
                        <div>
                            {{ addDutchTranslatedDifferentSubtextRight(row.id)}}
                        </div>
                    </template>
                </td>
            </tr>
        </tbody>
    </v-table>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import HorizontalBreedingValueBar from '@/components/HorizontalBreedingValueBar.vue';
import { formatNumber } from '@/scripts/format-number';
import { BullDetail } from '@/models/enums';
import { translateDaysMixin } from '../mixins/translate-days';
import { Locales } from '@/i18n/config/locales';
import { formatValueOrDash } from '@/scripts/format-value-or-dash';
import { formatLocale } from '@/scripts/format-locale';
import {displayDecimals} from "@/scripts/display-numbers-decimals";
import { formatBreedingValues } from "@/scripts/format-breeding-values";


export default defineComponent({
    name: 'BreedingGeneralTable',
    mixins: [translateDaysMixin],
    components: {
        HorizontalBreedingValueBar
    },
    props: {
        bodyRows: {
            type: Array,
        },
        headerRows: {
            type: Array,
        },
        hideHeader: {
            type: Boolean,
            default: false
        },
        withSubText: {
            type: Boolean,
            required: false,
            default: false
        },
    },
    data() {
        return {
            headerText: false
        }
    },
    methods: {
      showIndentationForMilkingRobot(value: string): string {
        if (typeof value === "string") {
          if (value.includes("robot") && !value.includes("index")) {
            return '\xa0\xa0\xa0' + value;
          }

          if (value.includes("Recovery") || value.includes("Stability") || value.includes("Herstel") || value.includes("Stabiliteit")){
            return '\xa0\xa0\xa0' + value;
          }
        }

        return value;
      },
        formatNumber(value: number): string {
            return formatNumber(value?.toString())
        },
        formatBreedingValues(value: object) {
            return formatBreedingValues(value)
        },
        returnNumberorString(value, tagname) {
            if (!value) {
                return '-'
            }

            if (tagname === 'FoodCostSavingMaintenance'){
              return this.$n(Number(displayDecimals(value, 2)), 'decimal', formatLocale(this.$i18n.locale));
            }

            if (tagname === 'DryMatterIntake' || tagname === 'Ureum') {
                return this.$n(Number(value), 'decimal', formatLocale(this.$i18n.locale))
            }
            else {
                return value
            }
        },
        formatEmptyToDash(value: number | string | undefined, round?: boolean): number | string | undefined {
            return formatValueOrDash(value, round);
        },
        setHeaderForHOVOBar(value: string, indexBegin: number, indexEnd: number) {
            if (!value || !indexEnd) {
                return;
            }
            return value.substr(indexBegin, indexEnd)
        },
        addDutchTranslatedSubtextLeft(tag: string): string {
            if (this.$i18n.locale === Locales.nlNl) {
                switch (tag) {
                    case BullDetail.HEIGHT:
                        return this.$t('details.sub-tags.tagSmallSize')
                    case BullDetail.ADVANCE:
                        return this.$t('details.sub-tags.tagSmallWidth')
                    case BullDetail.CONTENT:
                        return this.$t('details.sub-tags.tagFew')
                    case BullDetail.OPEN:
                        return this.$t('details.sub-tags.tagFew')
                    case BullDetail.CONDITIONSCORE:
                        return this.$t('details.sub-tags.tagFew')
                    case BullDetail.CROSSPOS:
                        return this.$t('details.sub-tags.tagHighPins')
                    case BullDetail.CROSSWIDTH:
                        return this.$t('details.sub-tags.tagSmallWidth')
                    case BullDetail.LEGPOSBACK:
                        return this.$t('details.sub-tags.tagHockedIn')
                    case BullDetail.LEGPOSSIDE:
                        return this.$t('details.sub-tags.tagStraight')
                    case BullDetail.CLAWANGLE:
                        return this.$t('details.sub-tags.tagFew')
                    case BullDetail.FRONTLEGPOS:
                        return this.$t('details.sub-tags.tagOutward')
                    case BullDetail.LEGUSE:
                        return this.$t('details.sub-tags.tagWeak')
                    case BullDetail.FRONTUDDERATTA:
                        return this.$t('details.sub-tags.tagLoose')
                    case BullDetail.FRONTTREAT:
                        return this.$t('details.sub-tags.tagWide')
                    case BullDetail.TEATLENGTH:
                        return this.$t('details.sub-tags.tagKort')
                    case BullDetail.UDDERDEPTH:
                        return this.$t('details.sub-tags.tagDeep')
                    case BullDetail.BACKUDDERHEIGHT:
                        return this.$t('details.sub-tags.tagLow')
                    case BullDetail.STIFLE:
                        return this.$t('details.sub-tags.tagWeak')
                    case BullDetail.BACKTEAT:
                        return this.$t('details.sub-tags.tagWide')
                    case BullDetail.UDDERBALANCE:
                        return this.$t('details.sub-tags.tagLowHind')
                    default:
                        return ''
                }
            } else {
                switch (tag) {
                    case BullDetail.HEIGHT:
                        return this.$t('details.sub-tags.tagShort')
                    case BullDetail.ADVANCE:
                        return this.$t('details.sub-tags.tagNarrow')
                    case BullDetail.CONTENT:
                        return this.$t('details.sub-tags.tagShallow')
                    case BullDetail.OPEN:
                        return this.$t('details.sub-tags.tagClosed')
                    case BullDetail.CONDITIONSCORE:
                        return this.$t('details.sub-tags.tagSkinny')
                    case BullDetail.CROSSPOS:
                        return this.$t('details.sub-tags.tagHighPins')
                    case BullDetail.CROSSWIDTH:
                        return this.$t('details.sub-tags.tagNarrow')
                    case BullDetail.LEGPOSBACK:
                        return this.$t('details.sub-tags.tagHockedIn')
                    case BullDetail.LEGPOSSIDE:
                        return this.$t('details.sub-tags.tagStraight')
                    case BullDetail.CLAWANGLE:
                        return this.$t('details.sub-tags.tagLow')
                    case BullDetail.FRONTLEGPOS:
                        return this.$t('details.sub-tags.tagOutward')
                    case BullDetail.LEGUSE:
                        return this.$t('details.sub-tags.tagWeak')
                    case BullDetail.FRONTUDDERATTA:
                        return this.$t('details.sub-tags.tagWeak')
                    case BullDetail.FRONTTREAT:
                        return this.$t('details.sub-tags.tagWide')
                    case BullDetail.TEATLENGTH:
                        return this.$t('details.sub-tags.tagShort')
                    case BullDetail.UDDERDEPTH:
                        return this.$t('details.sub-tags.tagDeep')
                    case BullDetail.BACKUDDERHEIGHT:
                        return this.$t('details.sub-tags.tagLow')
                    case BullDetail.STIFLE:
                        return this.$t('details.sub-tags.tagWeak')
                    case BullDetail.BACKTEAT:
                        return this.$t('details.sub-tags.tagWide')
                    case BullDetail.UDDERBALANCE:
                        return this.$t('details.sub-tags.tagLowHind')
                    default:
                        return ''
                }
            }
        },
        addDutchTranslatedDifferentSubtextRight(tag: string): string {
            if (this.$i18n.locale === Locales.nlNl) {
                switch (tag) {
                    case BullDetail.HEIGHT:
                        return this.$t('details.sub-tags.tagLarge')
                    case BullDetail.ADVANCE:
                        return this.$t('details.sub-tags.tagBroad')
                    case BullDetail.CONTENT:
                        return this.$t('details.sub-tags.tagMany')
                    case BullDetail.OPEN:
                        return this.$t('details.sub-tags.tagMany')
                    case BullDetail.CONDITIONSCORE:
                        return this.$t('details.sub-tags.tagMany')
                    case BullDetail.CROSSPOS:
                        return this.$t('details.sub-tags.tagInclined')
                    case BullDetail.CROSSWIDTH:
                        return this.$t('details.sub-tags.tagBroad')
                    case BullDetail.LEGPOSBACK:
                        return this.$t('details.sub-tags.tagStraight')
                    case BullDetail.LEGPOSSIDE:
                        return this.$t('details.sub-tags.tagCurved')
                    case BullDetail.CLAWANGLE:
                        return this.$t('details.sub-tags.tagMany')
                    case BullDetail.FRONTLEGPOS:
                        return this.$t('details.sub-tags.tagParallel')
                    case BullDetail.LEGUSE:
                        return this.$t('details.sub-tags.tagPowerful')
                    case BullDetail.FRONTUDDERATTA:
                        return this.$t('details.sub-tags.tagVast')
                    case BullDetail.FRONTTREAT:
                        return this.$t('details.sub-tags.tagNauw')
                    case BullDetail.TEATLENGTH:
                        return this.$t('details.sub-tags.tagLong')
                    case BullDetail.UDDERDEPTH:
                        return this.$t('details.sub-tags.tagShallow')
                    case BullDetail.BACKUDDERHEIGHT:
                        return this.$t('details.sub-tags.tagHigh')
                    case BullDetail.STIFLE:
                        return this.$t('details.sub-tags.tagSterk')
                    case BullDetail.BACKTEAT:
                        return this.$t('details.sub-tags.tagNauw')
                    case BullDetail.UDDERBALANCE:
                        return this.$t('details.sub-tags.tagHigherHind')
                    default:
                        return ''
                }
            } else {
                switch (tag) {
                    case BullDetail.HEIGHT:
                        return this.$t('details.sub-tags.tagTall')
                    case BullDetail.ADVANCE:
                        return this.$t('details.sub-tags.tagWide')
                    case BullDetail.CONTENT:
                        return this.$t('details.sub-tags.tagDeep')
                    case BullDetail.OPEN:
                        return this.$t('details.sub-tags.tagOpen')
                    case BullDetail.CONDITIONSCORE:
                        return this.$t('details.sub-tags.tagFat')
                    case BullDetail.CROSSPOS:
                        return this.$t('details.sub-tags.tagSlopedPins')
                    case BullDetail.CROSSWIDTH:
                        return this.$t('details.sub-tags.tagWide')
                    case BullDetail.LEGPOSBACK:
                        return this.$t('details.sub-tags.tagStraight')
                    case BullDetail.LEGPOSSIDE:
                        return this.$t('details.sub-tags.tagCurved')
                    case BullDetail.CLAWANGLE:
                        return this.$t('details.sub-tags.tagSteep')
                    case BullDetail.FRONTLEGPOS:
                        return this.$t('details.sub-tags.tagParallel')
                    case BullDetail.LEGUSE:
                        return this.$t('details.sub-tags.tagStrong')
                    case BullDetail.FRONTUDDERATTA:
                        return this.$t('details.sub-tags.tagStrong')
                    case BullDetail.FRONTTREAT:
                        return this.$t('details.sub-tags.tagNarrow')
                    case BullDetail.TEATLENGTH:
                        return this.$t('details.sub-tags.tagLong')
                    case BullDetail.UDDERDEPTH:
                        return this.$t('details.sub-tags.tagShallow')
                    case BullDetail.BACKUDDERHEIGHT:
                        return this.$t('details.sub-tags.tagHigh')
                    case BullDetail.STIFLE:
                        return this.$t('details.sub-tags.tagStrong')
                    case BullDetail.BACKTEAT:
                        return this.$t('details.sub-tags.tagNarrow')
                    case BullDetail.UDDERBALANCE:
                        return this.$t('details.sub-tags.tagHigherHind')
                    default:
                        return ''
                }
            }
        },

        getBreedingDeviationValues(id?: string, deviationArray?: Array<number>) {
            if (deviationArray) {
                return deviationArray
                // for example: [92, 96, 100, 104, 108]
            }
            switch (id) {
                // Component 'HorizontalBreedingValueBar' uses this array to calculate the grey lines underneath the bar
                // First and Last element are used to calculate the grey lines.
                // Thus 0's don't matter when the header is hidden.
                case BullDetail.LIFESPAN:
                    return [-774, 0, 0, 0, 0, 0, 774]
                case BullDetail.DRYMATTER:
                    return [-3.6, 0, 0, 0, 0, 0, 3.6]
                case BullDetail.FOODCOSTMAIN:
                    return [-2.22, 0, 0, 0, 0, 0, 2.22]
                case BullDetail.FOODCOST:
                    return [-213, 0, 0, 0, 0, 0, 213]
                case BullDetail.UREUM:
                    return[-6.3, 0, 0, 0, 0, 0, 6.3]
                case BullDetail.BODYWEIGHT:
                    return [88, 0, 0, 100, 0, 0, 112]
                case BullDetail.LIFEPRODINDEX:
                    return [-7500, 0, 0, 0, 0, 0, 7500]
                case BullDetail.LIFEPRODMILK:
                    return [-24000, 0, 0, 0, 0, 0, 24000]
                case BullDetail.LIFEPRODFAT:
                    return [-1200, 0, 0, 0, 0, 0, 1200]
                case BullDetail.LIFEPRODLACT:
                    return [-900, 0, 0, 0, 0, 0, 900]
                default:
                    return [88, 92, 96, 100, 104, 108, 112]
            }
        },

        getCellClass(cell: any) {
            if (cell.slotName === 'bar-sub-text-left') {
                return 'barSubTextLeft'
            }
            if (cell.slotName === 'bar-sub-text-right') {
                return 'barSubTextRight'
            }
            if (cell.slotName === 'text-left') {
                return 'text-left'
            }
            return 'text-end'
        }
    }
});
</script>

<style lang="scss" scoped>
@import '../scss/breeding.scss';

.custom-bar-header {
    display: flex;
    justify-content: space-between 
}

.subTextLeft, .subTextRight {
    z-index: 1; // positioned above the grey lines
}

.table-body {

    &.barSubTextLeft {
        text-align: right;
    }

    &.barSubTextLeft,
    &.barSubTextRight {
        color: #928782;
        font-size: 12px;
        width: 50px;
        min-width: 50px;
        max-width: 50px;
        padding: 0;
    }
}

.general-header {
    // applies styling only for general 3 colomn tables
    .fixed-width {
        width: 33%;
        word-break: break-all; 
        hyphens: auto;

        &.general {
            width: 30%;
        }
        &.bar {
            width: 36%;
        }

        &.FW {
            width: 14%;
        }
    }
}

.detailed-header {
    // applies styling only for detailed 7 colomn tables
    .fixed-width {
        word-break: break-all; 
        hyphens: auto;
        min-width: 100px;

        &.empty {
            width: 25%;
            min-width: 200px;
        }

        &.bar {
            width: 35%;
            min-width: 250px;
        }

        &.FW {
            // width: 14%;
            min-width: 75px;
            width: 75px;
        }
    }
}

</style>
