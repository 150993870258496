import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "text-end" }
const _hoisted_3 = { class: "header-sub-text" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 1,
  class: "space-reserver"
}
const _hoisted_6 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_table = _resolveComponent("v-table")!

  return (_openBlock(), _createBlock(_component_v_table, { density: "compact" }, {
    default: _withCtx(() => [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headerRows[0].cells, (item, index) => {
            return (_openBlock(), _createElementBlock("th", {
              key: item.id,
              class: "text-end"
            }, [
              (item.slotName === 'custom-styled-column1')
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, _toDisplayString(item.value), 1),
                    _createElementVNode("div", _hoisted_3, [
                      (_ctx.footerRows[0].cells[index].sourceCompany)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_4, " (" + _toDisplayString(_ctx.$t('details.source.' + _ctx.footerRows[0].cells[index].sourceCompany)) + ") ", 1))
                        : (_openBlock(), _createElementBlock("p", _hoisted_5))
                    ])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createElementVNode("p", null, _toDisplayString(item.value), 1),
                    _cache[0] || (_cache[0] = _createElementVNode("p", { class: "space-reserver" }, null, -1))
                  ]))
            ]))
          }), 128))
        ])
      ]),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bodyRows, (row) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: row.id
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.cells, (cell) => {
              return (_openBlock(), _createElementBlock("td", {
                key: cell.id,
                class: "tabular-nums text-end"
              }, _toDisplayString(cell.value), 1))
            }), 128))
          ]))
        }), 128))
      ]),
      _createElementVNode("tfoot", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.footerRows, (row) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: row.id
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.cells, (cell) => {
              return (_openBlock(), _createElementBlock("td", {
                key: cell.id,
                class: "tabular-nums text-end"
              }, _toDisplayString(cell.value), 1))
            }), 128))
          ]))
        }), 128))
      ])
    ]),
    _: 1
  }))
}