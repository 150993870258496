<template>
  <v-container fluid class="pl-0 pr-0">
    <v-row>
      <v-col cols="12" md="6" class="pr-md-6 pr-sm-0">
        <v-alert v-if="!hasMarkerEffects" icon="$alert" class="mb-6" type="warning" variant="tonal" :title="$t('details.genomics-warning-alert-title')"></v-alert>
        <v-alert 
          v-else
          color="info"
          icon="$info"
          variant="tonal"
          :title="$t('details.genomics-alert-header')"
          class="mb-6"
          min-width="350px">
          <ul>
            <li>{{ $t('details.genomics-alert-text-one') }}</li>
            <li>{{ $t('details.genomics-alert-text-two') }}</li>
            <li>{{ $t('details.genomics-alert-text-three') }}</li>
            <li>{{ $t('details.genomics-alert-text-four') }}</li>
            <li>{{ $t('details.genomics-alert-text-five') }}</li>
          </ul>
        </v-alert>
      </v-col>
      <v-col v-if="hasMarkerEffects" cols="12" md="6" class="pl-md-6 pl-sm-0">
        <GeneralTableView 
          class="mb-6"
          v-if="generalBreedingValueGroupList && generalBreedingValueGroupList[0] && generalBreedingValueGroupList[0].breedingValueList.length > 0"
          :body-rows="mapGeneralBreedingValues(
            generalBreedingValueGroupList[0].breedingValueList,
            generalBreedingValueGroupList[0].tagName
          )
          " 
          :header-rows="generalColumns" />
      </v-col>
    </v-row>
    <v-row v-if="hasMarkerEffects">
      <v-col cols="12" md="6" class="pr-md-6 pr-sm-0">
        <h2 class="pl-md-0 pl-3">{{ $t("details.production.header") }}</h2>
        <CustomTableView
          class="mb-6"
          v-if="productionBreedingValueGroupList && productionBreedingValueGroupList[0] && productionBreedingValueGroupList[0].breedingValueList.length > 0"
          :body-rows="mapCustomProductionBreedingValues(
            productionBreedingValueGroupList[0].breedingValueList,
            productionBreedingValueGroupList[0].tagName
          )
          " :header-rows="customColumns" />

        <h2 class="pl-md-0 pl-3">{{ $t("details.efficiency.header") }}</h2>
        <CustomTableView 
          class="mb-6"
          v-if="efficiencyBreedingValueGroupList && efficiencyBreedingValueGroupList[0] && efficiencyBreedingValueGroupList[0].breedingValueList.length > 0"
          :body-rows="mapCustomBreedingValues(
            efficiencyBreedingValueGroupList[0].breedingValueList,
            efficiencyBreedingValueGroupList[0].tagName
          )
          " :header-rows="customColumns" />

        <h2 class="pl-md-0 pl-3">{{ $t("details.health.header") }}</h2>
        <GeneralTableView
          class="mb-6"
          v-if="healthBreedingValueGroupList && healthBreedingValueGroupList[0] && healthBreedingValueGroupList[0].breedingValueList.length > 0"
          :body-rows="mapGeneralBreedingValues(
            healthBreedingValueGroupList[0].breedingValueList,
            healthBreedingValueGroupList[0].tagName
          )
          " :header-rows="generalColumns" />

        <h2 class="pl-md-0 pl-3">{{ $t("details.functional.header") }}</h2>
        <GeneralTableView
          class="mb-6"
          v-if="functionalBreedingValueGroupList && functionalBreedingValueGroupList[0] && functionalBreedingValueGroupList[0].breedingValueList.length > 0"
          :body-rows="mapGeneralBreedingValues(
            functionalBreedingValueGroupList[0].breedingValueList,
            functionalBreedingValueGroupList[0].tagName
          )
          " :header-rows="generalColumns" />

      </v-col>
      <v-col cols="12" md="6" class="pl-md-6 pl-sm-0">
        <h2 class="pl-md-0 pl-3">{{ $t("details.birth.header") }}</h2>
        <GeneralTableView
          class="mb-6"
          v-if="birthBreedingValueGroupList && birthBreedingValueGroupList[0] && birthBreedingValueGroupList[0].breedingValueList.length > 0"
          :body-rows="mapGeneralBreedingValues(
            birthBreedingValueGroupList[0].breedingValueList,
            birthBreedingValueGroupList[0].tagName
          )
          " :header-rows="generalColumns" />

        <h2 class="pl-md-0 pl-3">{{ $t("details.exteriorabove.header") }}</h2>
        <GeneralTableView
          class="mb-6"
          v-if="exteriorAboveBreedingValueGroupList && exteriorAboveBreedingValueGroupList[0] && exteriorAboveBreedingValueGroupList[0].breedingValueList.length > 0" 
          :body-rows="mapGeneralBreedingValues(
            exteriorAboveBreedingValueGroupList[0].breedingValueList,
            exteriorAboveBreedingValueGroupList[0].tagName
          )
          " :header-rows="generalColumns" />

        <h2 class="pl-md-0 pl-3">{{ $t("details.exteriorbelow.header") }}</h2>
        <GeneralTableView
          class="mb-6"
          v-if="exteriorBelowBreedingValueGroupList && exteriorBelowBreedingValueGroupList[0] && exteriorBelowBreedingValueGroupList[0].breedingValueList.length > 0" 
          :body-rows="mapGeneralBreedingValues(
            exteriorBelowBreedingValueGroupList[0].breedingValueList,
            exteriorBelowBreedingValueGroupList[0].tagName
          )
          " :header-rows="generalColumns" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters, useStore } from "vuex";
import { computed, ComputedRef } from "vue";
import { BreedingValue, BreedingValueSubValue } from "../models/bullinfo";
import GeneralTableView from "./BlockView/GeneralTableView.vue";
import CustomTableView from "./BlockView/CustomTableView.vue";
import { TableName, HeaderValues } from "../models/enums";
import { BreedingValueGroup } from "../models/bullinfo";
import { GeneralProdColumns, CustomColumns } from "../models/table-headers";
import { formatSignedValue } from '@/scripts/format-signed-value';

export default defineComponent({
  name: "GenomicsView",

  data() {
    return {
      generalColumns: [
        {
          id: "general-column",
          cells: GeneralProdColumns
        },
      ],
      customColumns: [
        {
          id: "production-column",
          cells: CustomColumns
        },
      ],
    };
  },

  props: {
    hasMarkerEffects: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const store = useStore();

    const generalBreedingValueGroupList: ComputedRef<BreedingValueGroup> =
      computed(() => {
        return store.state.bullDetail.breedingValueBase?.breedingValueGroupList?.filter((item) => item.tagName == TableName.GENERAL);
      });
    const healthBreedingValueGroupList: ComputedRef<BreedingValueGroup> =
      computed(() => {
        return store.state.bullDetail.breedingValueBase?.breedingValueGroupList?.filter((item) => item.tagName == TableName.HEALTH);
      });
    const functionalBreedingValueGroupList: ComputedRef<BreedingValueGroup> =
      computed(() => {
        return store.state.bullDetail.breedingValueBase?.breedingValueGroupList?.filter((item) => item.tagName == TableName.FUNCTIONAL);
      });
    const birthBreedingValueGroupList: ComputedRef<BreedingValueGroup> =
      computed(() => {
        return store.state.bullDetail.breedingValueBase?.breedingValueGroupList?.filter((item) => item.tagName == TableName.BIRTH);
      });
    const exteriorAboveBreedingValueGroupList: ComputedRef<BreedingValueGroup> =
      computed(() => {
        return store.state.bullDetail.breedingValueBase?.breedingValueGroupList?.filter((item) => item.tagName == TableName.EXTERIORABOVE);
      });

    const exteriorBelowBreedingValueGroupList: ComputedRef<BreedingValueGroup> =
      computed(() => {
        return store.state.bullDetail.breedingValueBase?.breedingValueGroupList?.filter((item) => item.tagName == TableName.EXTERIORBELOW);
      });

    const efficiencyBreedingValueGroupList: ComputedRef<BreedingValueGroup> =
      computed(() => {
        return store.state.bullDetail.breedingValueBase?.breedingValueGroupList?.filter((item) => item.tagName == TableName.EFFICIENCY);
      });
    const productionBreedingValueGroupList: ComputedRef<BreedingValueGroup> =
      computed(() => {
        return store.state.bullDetail.breedingValueBase?.breedingValueGroupList?.filter((item) => item.tagName == TableName.PRODUCTION);
      });

    const noMarkerEffectsFound = computed(() => {
      if (productionBreedingValueGroupList.value && productionBreedingValueGroupList.value[0]) {
          return productionBreedingValueGroupList.value[0].breedingValueList.every(item => !item.value);
      } else if (generalBreedingValueGroupList.value && generalBreedingValueGroupList.value[0]) {
          return generalBreedingValueGroupList.value[0].breedingValueList.every(item => !item.value);
      } else if (healthBreedingValueGroupList.value && healthBreedingValueGroupList.value[0]) {
          return healthBreedingValueGroupList.value[0].breedingValueList.every(item => !item.value);
      } else if (functionalBreedingValueGroupList.value && functionalBreedingValueGroupList.value[0]) {
          return functionalBreedingValueGroupList.value[0].breedingValueList.every(item => !item.value);
      } else if (birthBreedingValueGroupList.value && birthBreedingValueGroupList.value[0]) {
          return birthBreedingValueGroupList.value[0].breedingValueList.every(item => !item.value);
      } else if (exteriorAboveBreedingValueGroupList.value && exteriorAboveBreedingValueGroupList.value[0]) {
          return exteriorAboveBreedingValueGroupList.value[0].breedingValueList.every(item => !item.value);
      } else if (exteriorBelowBreedingValueGroupList.value && exteriorBelowBreedingValueGroupList.value[0]) {
          return exteriorBelowBreedingValueGroupList.value[0].breedingValueList.every(item => !item.value);
      } else if (efficiencyBreedingValueGroupList.value && efficiencyBreedingValueGroupList.value[0]) {
          return efficiencyBreedingValueGroupList.value[0].breedingValueList.every(item => !item.value);
      }
        return true;
    })


    return {
      generalBreedingValueGroupList,
      healthBreedingValueGroupList,
      functionalBreedingValueGroupList,
      birthBreedingValueGroupList,
      exteriorAboveBreedingValueGroupList,
      exteriorBelowBreedingValueGroupList,
      efficiencyBreedingValueGroupList,
      productionBreedingValueGroupList,
      noMarkerEffectsFound
    };
  },

  components: {
    GeneralTableView,
    CustomTableView
  },

  computed: {
    ...mapGetters({
      bullDetail: "getBullDetail",
    }),
  },

  methods: {
    mapCustomProductionBreedingValues(list: BreedingValue[], tagName: string) {
      return list
        // .filter((value: BreedingValue) => value.showOnDetailView)
        .map((value: BreedingValue) => ({
          id: value.tagName,
          cells: [
            {
              id: value.tagName,
              value: this.$t(`details.${tagName.toLowerCase()}.${value.tagName}`),
              alignment: 'text-left'
            },
            {
              id: 'value',
              value: formatSignedValue(value.value)
            },
            {
              id: '%btbh',
              value: formatSignedValue(value.reliabilityPercentage)
            },
          ],
        }));
    },
  
    mapCustomBreedingValues(list: BreedingValue[], tagName: string) {
      let mappedBreedingValues;
      // eslint-disable-next-line
        mappedBreedingValues = list.map((value: BreedingValue) => ({
          id: value.tagName,
          cells: [
            {
              id: value.tagName,
              value: this.$t(`details.${tagName.toLowerCase()}.${value.tagName}`),
              alignment: 'text-left'
            },
            {
              id: HeaderValues.VALUE,
              value: formatSignedValue(value.value)
            },
            {
              id: HeaderValues.BTBH,
              value: formatSignedValue(value.reliabilityPercentage)
            },
          ],
        }))

      return mappedBreedingValues;
    },

    mapGeneralBreedingValues(list: BreedingValue[], tagName: string) {
      const breedingValueArray: BreedingValue[] = [];
      for (let index = 0; index < list.length; index++) {
        const element: BreedingValue = list[index];
        breedingValueArray.push(element);

        if (element.tagName === "BirthIndex" && tagName === "Birth") {
          const subValueList = element.breedingValueSubValueList;
          if (subValueList === undefined){
            //
          }
          else{
            const birthEase = subValueList.filter(
              (subvalue: BreedingValueSubValue) =>
                subvalue.tagName === "BirthEase"
            )[0];
            // birthEase.showOnDetailView = true;
            breedingValueArray.push(birthEase as BreedingValue);
          }
        }
      }

      const alist = breedingValueArray
        .map((value: BreedingValue) => {
          if (!value || !value.tagName) {
            return undefined
          }

          return {
            id: value.tagName,
            cells: [
              {
                id: value.tagName,
                value: this.$t(`genomicsView.${value.tagName}`),
                alignment: 'text-left'
              },
              {
                id: HeaderValues.VALUE,
                value: formatSignedValue(value.value)
              },
              {
                id: HeaderValues.BTBH,
                value: formatSignedValue(value.reliabilityPercentage)
              },
            ],
          };
        })
        .filter(item => item !== undefined)

      return alist
    },
  },
});
</script>
