import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "pl-md-0 pl-3" }
const _hoisted_2 = { class: "pl-md-0 pl-3" }
const _hoisted_3 = { class: "pl-md-0 pl-3" }
const _hoisted_4 = { class: "pl-md-0 pl-3" }
const _hoisted_5 = { class: "pl-md-0 pl-3" }
const _hoisted_6 = { class: "pl-md-0 pl-3" }
const _hoisted_7 = { class: "pl-md-0 pl-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductionTable = _resolveComponent("ProductionTable")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_BreedingGeneralTable = _resolveComponent("BreedingGeneralTable")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, {
    fluid: "",
    class: "pl-0 pr-0"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t("details.production.header")), 1),
              _createVNode(_component_ProductionTable, {
                "header-rows": _ctx.productionColumns,
                "body-rows": _ctx.mapProductionBreedingValues(_ctx.lactationInfoList),
                "footer-rows": _ctx.mapFooterRowLactation(_ctx.lactationInfoList)
              }, null, 8, ["header-rows", "body-rows", "footer-rows"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t("details.efficiency.header")), 1),
              _createVNode(_component_BreedingGeneralTable, {
                "hide-header": "",
                "is-efficiency": "",
                "body-rows": _ctx.mapGeneralBreedingValues(_ctx.efficiencyBreedingValueGroupList[0].breedingValueList, _ctx.efficiencyBreedingValueGroupList[0].tagName),
                "header-rows": _ctx.customColumns
              }, null, 8, ["body-rows", "header-rows"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t("details.health.header")), 1),
              _createVNode(_component_BreedingGeneralTable, {
                "body-rows": _ctx.mapGeneralBreedingValues(_ctx.healthBreedingValueGroupList[0].breedingValueList, _ctx.healthBreedingValueGroupList[0].tagName),
                "header-rows": _ctx.customColumns
              }, null, 8, ["body-rows", "header-rows"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.$t("details.birth.header")), 1),
              _createVNode(_component_BreedingGeneralTable, {
                "body-rows": _ctx.mapGeneralBreedingValues(_ctx.birthBreedingValueGroupList[0].breedingValueList, _ctx.birthBreedingValueGroupList[0].tagName),
                "header-rows": _ctx.customColumns
              }, null, 8, ["body-rows", "header-rows"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.$t("details.exteriorabove.header")), 1),
              _createVNode(_component_BreedingGeneralTable, {
                "body-rows": _ctx.mapGeneralBreedingValues(_ctx.exteriorAboveBreedingValueGroupList[0].breedingValueList, _ctx.exteriorAboveBreedingValueGroupList[0].tagName),
                "header-rows": _ctx.customColumns
              }, null, 8, ["body-rows", "header-rows"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.$t("details.exteriorbelow.header")), 1),
              _createVNode(_component_BreedingGeneralTable, {
                "body-rows": _ctx.mapGeneralBreedingValues(_ctx.exteriorBelowBreedingValueGroupList[0].breedingValueList, _ctx.exteriorBelowBreedingValueGroupList[0].tagName),
                "header-rows": _ctx.customColumns,
                withSubText: ""
              }, null, 8, ["body-rows", "header-rows"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createElementVNode("h2", _hoisted_7, _toDisplayString(_ctx.$t("details.functional.header")), 1),
              _createVNode(_component_BreedingGeneralTable, {
                "body-rows": _ctx.mapGeneralBreedingValues(_ctx.functionalBreedingValueGroupList[0].breedingValueList, _ctx.functionalBreedingValueGroupList[0].tagName),
                "header-rows": _ctx.customColumns
              }, null, 8, ["body-rows", "header-rows"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}