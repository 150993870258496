import { SearchIndexClient, AzureKeyCredential, SearchOptions, SearchClient } from '@azure/search-documents';
import regexescape from 'regex-escape';


export type SearchServiceResponse<T> = T | [Error | any];

export interface ISearchService{
  endPoint: string;
  apiKey: string;
  indexClient: SearchIndexClient;
  searchClient: SearchClient<unknown>;
  getSingle<T = unknown>(id: string, ): Promise<SearchServiceResponse<T>>;
  getArray<T = unknown>(searchOptions: SearchOptions<never>, value?: string,): Promise<SearchServiceResponse<T>>;
}

// const TOP_AMOUNT = 100; // Maximum number of search results
// Indexes: https://portal.azure.com/#@crv4all.onmicrosoft.com/resource/subscriptions/618e5fc5-24f2-4a06-96f6-2596f9128d3e/resourceGroups/CRV-Siresearch-tst-weu/providers/Microsoft.Search/searchServices/srch-crv-siresearch-index-tst-weu/indexes
const NAME_INDEX = "bullinfo_new";

export class AzureSearchService implements ISearchService {
  endPoint = process.env.VUE_APP_SEARCH_API_ENDPOINT as string;
  apiKey = process.env.VUE_APP_SEARCH_API_KEY as string;
  indexClient = new SearchIndexClient(this.endPoint, new AzureKeyCredential(this.apiKey));
  searchClient = this.indexClient.getSearchClient(NAME_INDEX);

  async getSingle<T = unknown>(id: string): Promise<SearchServiceResponse<T>> {
    const data = await this.searchClient.getDocument(id) as T;
    return data;
  }

  async getArray<T = unknown>(searchOptions: SearchOptions<never>, value?: string | undefined): Promise<SearchServiceResponse<T>> {
    value = regexescape(value)

    if (value === undefined) {
      value = "";
    }

    const topAmountThreshold = 5000;

    searchOptions.top = searchOptions.top ?? topAmountThreshold; // Default Top-amount Threshold 
    const searchTerm = this.constructProperSearchTerm(value);
    const searchResults = await this.searchClient.search(searchTerm, searchOptions);

    if (value !== "" && value !== "*" && searchResults.count !== undefined && searchResults.count > topAmountThreshold) {
      console.error(`Too many results: ${searchResults.count}`);
      return [new Error(`Too many results: ${searchResults.count}`)];
    }

    const jsonArray: T[] = [];
    let i = 0;
    for await (const result of searchResults.results) {
      const jsonObject = `${JSON.stringify(result.document)}`;
      const jsonGeneric = JSON.parse(jsonObject) as T;
      jsonArray[i] = jsonGeneric;
      i++;
    }

    if (searchOptions.top > 1) {
      // don't show on initial load to get the amount of results
      console.log("\"" + searchTerm + "\" " + "count: " + jsonArray.length); 
    }
    return jsonArray as unknown as T;
  }

  /**
   * This method is to modify the search term so that it retrieves correct results.
   * The problem is special characters, i.e. (-, &, etc.)
   * The analyzer of the index is set to standard, 
   * an alternative to this method/solution is to set that to "whitespace".
   * This is not a valid option for this use case because it
   * doesn't support wildcard-search.
   * 
   * The standard analyzer splits the searchString into tokens using whitespace/special characters
   * except for characters <.:'> when not suffixed by a space.
   * For instance:
   * bla*foo$bar-fred
   * Tokens in the index that are used to match the searchString:
   * bla
   * foo
   * bar
   * fred
   * 
   * The pattern analyzer (which is currently used) splits the searchString into tokens using a regex,
   * we use whitespace ("//s+"). Special characters are then preserved in the tokens
   * so we can search on them and the table returns the correct selection.
   * 
   * Only a wildcard at the end of the searchString is useful because the table
   * used in the app does not support searching on multiple substrings.
   * For instance:
   * API ai-search on "bla* foo*" returns 4 results:
   * blaa foo
   * bla bla foo
   * bla fooo
   * foo bla
   * Table only displays
   * bla foo
   * bla fooo 
   * @param value
   */
  constructProperSearchTerm(value: string){
    return value.replace(/[^a-zA-Z0-9]/g, ' ').trim() + "*";
  }
}
