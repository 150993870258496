import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, toHandlers as _toHandlers, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { class: "pedigree__card__row__static" }
const _hoisted_2 = { class: "pedigree__card__row__static" }
const _hoisted_3 = { class: "pedigree__card__row__static" }
const _hoisted_4 = { class: "pedigree__card__row__static" }
const _hoisted_5 = { class: "pedigree__card__row__static" }
const _hoisted_6 = { class: "pedigree__card__row__static" }
const _hoisted_7 = { class: "pedigree__card__row__static" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, _mergeProps({ elevation: "6" }, _toHandlers({ click: _ctx.isSire ? _ctx.handleClick : null })), {
    default: _withCtx(() => [
      _createVNode(_component_v_row, {
        class: _normalizeClass(["px-4 pb-4 pt-2", _ctx.getClickableClass])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createElementVNode("p", null, [
                _createElementVNode("b", null, _toDisplayString(_ctx.formatCardHeaderName(_ctx.pedigreeBull?.shortName, _ctx.pedigreeBull?.fullName)), 1),
                (!_ctx.isSire)
                  ? (_openBlock(), _createBlock(_component_v_icon, {
                      key: 0,
                      icon: "$genderFemale",
                      color: "#b74d6d"
                    }))
                  : (_openBlock(), _createBlock(_component_v_icon, {
                      key: 1,
                      icon: "$genderMale",
                      color: "#2f679c"
                    }))
              ]),
              _createElementVNode("p", null, _toDisplayString((_ctx.pedigreeBull?.fullName)), 1)
            ]),
            _: 1
          }),
          (_ctx.isSire)
            ? (_openBlock(), _createBlock(_component_v_col, {
                key: 0,
                cols: "2",
                class: "mt-3 d-flex justify-end"
              }, {
                default: _withCtx(() => [
                  (_ctx.isSire)
                    ? (_openBlock(), _createBlock(_component_v_icon, {
                        key: 0,
                        icon: "$chevronRight",
                        color: "#7F746F"
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["class"]),
      (_ctx.type === 'child')
        ? (_openBlock(), _createBlock(_component_v_row, {
            key: 0,
            class: "px-4 mt-n6 pb-4"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, null, {
                default: _withCtx(() => [
                  _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t('details.summary.first.artificialInseminationNumber')), 1),
                  _createElementVNode("p", null, _toDisplayString(_ctx.formatEmptyToDash(_ctx.pedigreeBull?.artificialInseminationNumber)), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_v_row, { class: "px-4 mt-n6 pb-4" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('details.summary.first.interbullNumber')), 1),
              _createElementVNode("p", null, _toDisplayString(_ctx.formatEmptyToDash(_ctx.pedigreeBull?.interbullNumber)), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, { class: "px-4 mt-n6 pb-4" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('details.summary.first.dateOfBirthSmall')), 1),
              _createElementVNode("p", null, _toDisplayString(_ctx.formatDate(_ctx.pedigreeBull?.dateOfBirth)), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_ctx.type === 'child' || _ctx.type === 'parents')
        ? (_openBlock(), _createBlock(_component_v_row, {
            key: 1,
            class: "px-4 mt-n6 pb-4"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, null, {
                default: _withCtx(() => [
                  _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('details.summary.first.raceInfo')), 1),
                  _createElementVNode("p", null, _toDisplayString(_ctx.formatEmptyToDash(_ctx.pedigreeBull?.breed)), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.type === 'child')
        ? (_openBlock(), _createBlock(_component_v_row, {
            key: 2,
            class: "px-4 mt-n6 pb-4"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, null, {
                default: _withCtx(() => [
                  _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('details.summary.first.inheritedFactors')), 1),
                  _createElementVNode("p", null, _toDisplayString(_ctx.formatValueOrDash(_ctx.formatInheritedFactors(_ctx.pedigreeBull?.inheritedFactors))), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.type === 'child')
        ? (_openBlock(), _createBlock(_component_v_row, {
            key: 3,
            class: "px-4 mt-n6 pb-4"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, null, {
                default: _withCtx(() => [
                  _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('details.summary.first.inbreedingCoefficient')), 1),
                  _createElementVNode("p", null, _toDisplayString(_ctx.formatInbreedingCoefficient(_ctx.pedigreeBull?.inbreedingCoefficient)), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.type === 'child')
        ? (_openBlock(), _createBlock(_component_v_row, {
            key: 4,
            class: "px-4 mt-n6 pb-4"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, null, {
                default: _withCtx(() => [
                  _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t('details.summary.first.kinshipRate')), 1),
                  _createElementVNode("p", null, _toDisplayString(_ctx.formatEmptyToDash(_ctx.pedigreeBull?.kinshipRate)), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 16))
}