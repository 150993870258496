import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_table = _resolveComponent("v-table")!

  return (_openBlock(), _createBlock(_component_v_table, { density: "compact" }, {
    default: _withCtx(() => [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headerRows[0].cells, (item) => {
            return (_openBlock(), _createElementBlock("th", {
              key: item.id,
              class: _normalizeClass(_ctx.getClass(item))
            }, _toDisplayString(item.value), 3))
          }), 128))
        ])
      ]),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bodyRows, (row) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: row.id,
            class: _normalizeClass(row.id === 'NVI' ? 'nvi-styling' : '')
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.cells, (cell) => {
              return (_openBlock(), _createElementBlock("td", {
                key: cell.id,
                class: _normalizeClass([_ctx.setAlignment(cell), "fixed-width"])
              }, _toDisplayString(cell.value), 3))
            }), 128))
          ], 2))
        }), 128))
      ])
    ]),
    _: 1
  }))
}