import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"height":"70vh"} }
const _hoisted_2 = { class: "text-center sub-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_crv_fallback_content = _resolveComponent("crv-fallback-content")!
  const _component_SireFooter = _resolveComponent("SireFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_crv_fallback_content, {
        "image-name": "tools",
        title: _ctx.$t('maintenance-page.title'),
        text: _ctx.$t('maintenance-page.maintenance')
      }, null, 8, ["title", "text"]),
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t("maintenance-page.sorry")), 1)
    ]),
    _createVNode(_component_SireFooter)
  ], 64))
}