import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueI18n from './i18n/entry';
import store from './store/store';
import DomPortal from 'vue-dom-portal';
import VueStatic from 'vue-static';
import VueObserveVisibility from 'vue-observe-visibility';
import '@crv/vue-3-components/dist/style.css'
import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css'

import { vuetify } from '@/plugins/vuetify'

// TODO: When able import theme from monorepo, plus make below colors a standard

export const CrvThemeCopy = {
  dark: false,
  colors: {
    background: '#FFFFFF',
    surface: '#FFFFFF',
    primary: '#2F679C',
    'primary-darken-1': '#082947',
    secondary: '#DCEAF6',
    'secondary-darken-1': '#018786',
    error: '#DB3E3E',
    info: '#2F679C',
    success: '#00BE00',
    warning: '#FF9600',

    'base-orange-90': '#D39688',
    'base-orange-100': '#BB5E48',
    'base-blue-70': '#4881b5',
    'base-gray-40': '#D2CCC8',
    'base-green-80': '#8EAFA6',
    'base-green-90': '#4D7E70'
  },
}

const app = createApp(App);

app.use(VueI18n);
app.use(store);
app.use(DomPortal);
app.use(VueStatic);
app.use(VueObserveVisibility);
app.use(vuetify)
app.use(router).mount('#app');
