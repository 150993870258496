<template>
    <v-table density="compact">
    <thead>
      <tr>
        <th  
          v-for="item in headerRows[0].cells"
          :key="item.id" 
          :class="getClass(item)"
        >
          {{ item.value}}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="row in bodyRows"
        :key="row.id"
        :class="row.id === 'NVI' ? 'nvi-styling' : ''"
      >
        <td
          v-for="cell in row.cells"
          :key="cell.id"
          :class="setAlignment(cell)"
          class="fixed-width"
        >{{ cell.value }}</td>
      </tr>
    </tbody>
  </v-table>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'GeneralTableView',
    props: {
        bodyRows: {
            type: Array,
        },
        headerRows: {
            type: Array,
        }
    },
    methods: {
      getClass(item) {
        return item.id === 'general' ? 'general' : 'text-end'
      },
      setAlignment(value) {
            return value.alignment === 'text-left' ? 'text-left' : 'text-end'
        },
    }
});
</script>

<style lang="scss" scoped>
// @import '../../scss/detail.scss';

.table-header {
  .v-table table thead tr th {
    background-image: linear-gradient(
      rgba(236, 236, 236, 0.4),
      rgba(236, 236, 236, 0.4),
      rgba(236, 236, 236, 0.4)
    ) !important;
    font-weight: $font-weight-strong !important;
  }
}

.nvi-styling {
  font-weight: 800;
}

.fixed-width {
  width: 33%;
}
</style>